export default {
  /**
   * Connect to Slack.
   *
   * @returns Boolean
   */
  async connect (code) {
    const response = await axios.post(`/integrations/slack/connect`, {
      code
    })

    return response.data.success
  },

  /**
   * Get slack channels.
   *
   * @returns Object
   */
  async getChannels () {
    const response = await axios.get(`/integrations/slack/channels`)

    return response.data
  },

  /**
   * Get slack users.
   *
   * @returns Object
   */
  async getUsers () {
    const response = await axios.get(`/integrations/slack/users`)

    return response.data
  },

  /**
   * Send sample message to a slack channel.
   *
   * @param channel_id
   * @returns {Promise<*>}
   */
  async sendSampleMessage (channel_id) {
    const response = await axios.post(`/integrations/slack/send-sample-message`, {
      channel_id
    })

    return response.data
  }
}
