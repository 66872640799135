<template>
  <div></div>
</template>

<script>
import slackApi from '@/api/integrations/slackApi.js'

export default {
  data () {
    return {
      code: this.$route.query.code,
      state: this.$route.query.state
    }
  },

  async created () {
    if (!this.code || !this.state) {
      return false
    }

    await slackApi.connect(this.code)
    await this.refreshCurrentUser()

    this.redirect()
  },

  methods: {
    redirect () {
      const state = JSON.parse(atob(this.state))
      this.$router.push(state.redirectRoute)
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  }
}
</script>
